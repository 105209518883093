<template>
    <v-container>
        <NotificationsPage />
    </v-container>
</template>

<script>
import NotificationsPage from '@/components/notifications/Index.vue'

export default {
    components: {
        NotificationsPage
    }
}
</script>