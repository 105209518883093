<template>
    <v-container>
         <!-- HEADER -->
        <v-row>
            <v-col
                cols="12"
                class="mt-3 pa-4 text-left"
            >
                <v-icon
                    class="mb-4"
                    large
                >
                    mdi-bell
                </v-icon>
                <span class="text-h2 ml-2">Notifications</span>
            </v-col>
        </v-row>
        <v-row style="width: 100%">
            <v-card>
                test
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "NotificationsPage",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
    },
}
</script>